/*
 * @Author: sikonggpw 1327325804@qq.com
 * @Date: 2023-02-27 16:31:11
 * @LastEditors: sikonggpw 1327325804@qq.com
 * @LastEditTime: 2023-04-03 10:37:24
 * @FilePath: \gateway-web\src\enmu\selectType.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// const ccc = {}
// // 企业产业比职教产业少一个  职教研究=13
// const aaa = '电子信息产业=1,石油化工与新材料新能源产业=2,金融与新兴产业=3,纺织鞋服产业=4,建材家居与工艺制品产业=5,装备制造产业=6,食品饮料与生物医药产业=7,现代服务产业=8,数字经济产业=9,海洋经济产业=10,绿色经济产业=11,文旅经济产业=12,职教研究=13'.split(',')
// const bbb = '电子信息产业=1,石油化工与新材料新能源产业=2,金融与新兴产业=3,纺织鞋服产业=4,建材家居与工艺制品产业=5,装备制造产业=6,食品饮料与生物医药产业=7,现代服务产业=8,数字经济产业=9,海洋经济产业=10,绿色经济产业=11,文旅经济产业=12'.split(',')
// aaa.forEach(item => {
//   const arr = item.split('=')
//   ccc[arr[0]] = arr[1]
// })
// console.log(ccc);

// 职教专家
const occupationType = {
  '全部': 'all',
  "电子信息产业": "1",
  "石油化工与新材料新能源产业": "2",
  "金融与新兴产业": "3",
  "纺织鞋服产业": "4",
  "建材家居与工艺制品产业": "5",
  "装备制造产业": "6",
  "食品饮料与生物医药产业": "7",
  "现代服务产业": "8",
  "数字经济产业": "9",
  "海洋经济产业": "10",
  "绿色经济产业": "11",
  "文旅经济产业": "12",
  "职教研究": "13",
  "其他": "14",
}

// 企业
const enterpriseType = {
  '全部': 'all',
  "电子信息产业": "1",
  "石油化工与新材料新能源产业": "2",
  "金融与新兴产业": "3",
  "纺织鞋服产业": "4",
  "建材家居与工艺制品产业": "5",
  "装备制造产业": "6",
  "食品饮料与生物医药产业": "7",
  "现代服务产业": "8",
  "数字经济产业": "9",
  "海洋经济产业": "10",
  "绿色经济产业": "11",
  "文旅经济产业": "12",
  "职教研究": "13",
  "其他": "14",
}

// 所在单位类型
const typeUnit = {
  '规模以上企业': '1',
  '科技型中小企业': '2',
  '高新技术企业': '3',
  '国家级专精特新“小巨人”企业': '4',
  '福建省“专精特新”中小企业': '5',
  '福建省科技小巨人领军企业': '6',
  '技术先进型服务企业 ': '7',
  '服务型制造示范企业': '8',
  '农业产业化龙头企业': '9',
  '瞪羚企业': '10',
  '知识产权优势、示范企业': '11',
}
// 业务特长
const businessSpecialty = {
  '企业管理  ': '1',
  '法律服务  ': '2',
  '财务服务  ': '3',
  '市场开拓': '4',
  '技术研发  ': '5',
  '政策咨询  ': '6',
  '投融资 ': '7',
}

export {
  occupationType,
  enterpriseType,
  typeUnit,
  businessSpecialty
}