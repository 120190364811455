<template>
  <div class="Technology">
    <!-- <h3>技术领域</h3> -->
    <div class="technologyItem">
      <div class="title">{{ title }}</div>
      <div class="btnList">
        <span v-if="techfieldList['all']" @click="selectTagHandle('all')"
          :class="['el-btn', activeTag === 'all' ? 'active' : 'no-active']" data-index="all">全部</span>
        <span v-for="(item, key, index) in techfieldList" :data-index="item" :key="index + key"
          :class="['el-btn', activeTag === item ? 'active' : 'no-active']" @click="selectTagHandle(item)">{{ key }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    techfieldList: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeTag: 'all'
    };
  },
  watch: {
    tag: {
      handler(val) {
        if (val) {
          this.activeTag = val
        }
      },
      immediate: true
    }
  },
  methods: {
    selectTagHandle(val) {
      console.log("");
      this.activeTag = val;
      let tech = val === "all" ? "" : val;
      this.$emit("techHandle", tech);
    },
  },
};
</script>
<style lang="scss" scoped>
.Technology {
  /* padding: 10px 15px; */
  width: 100%;
  height: 112px;
  /* border: 1px solid #f2f2f2; */
  /* border-radius: 5px; */
}

.technologyItem {
  height: 112px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 30px 24px 0px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #F1F1F1;
  border-bottom: 1px solid #F1F1F1;
  .btnList {
    flex: 1;
  }
}

.title {
  width: 88px;
  height: 21px;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  border-right: 1px solid #E5E5E5;
  margin-right: 16px;
}

h3 {
  margin: 24px 0;
  font-size: 24px;
  font-weight: bold;
  color: #101010;
}

.el-btn {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 16px 8px 0px;
  padding: 5px 8px;
  /* line-height: 21px; */
  height: 24px;
  /* width: 44px; */
  font-size: 14px;
  /* font-family: SourceHanSansCN-Regular, SourceHanSansCN; */
  font-family: 思源黑体 CN Regular;
  font-weight: 400;
  color: #101010;
}

/* .el-btn:hover {
  color: #2885FF;
  border: none;
} */
.active {
  /* height: 38px; */
  background: #F5F9FF;
  border-radius: 6px;
  color: #2885FF;
  border: 1px solid #2885FF;
}


.no-active {
  border: none;
  color: #404040;
  background-color: #FFFFFF;
  border: 1px solid #fff;
}

.no-active:hover {
  color: #2885FF;
  border: 1px solid #fff;
}
</style>