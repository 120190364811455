import request from "../../../common/utils/request";

// 获取专家库信息(已废弃)
export function getProfessorInfo(params) {
  return request({
    url: "/itf/common/cjrh/traininglab/getExperts.json",
    method: "get",
    params:params,
  });
}
// 根据Id查询专家库详细信息(已废弃)
export function getExpertById(params) {
  return request({
    url: "/itf/common/cjrh/traininglab/getExpertById.json",
    method: "get",
    params:params,
  });
}

// 翻页查询职教专家库
export function getSchExperts(params) {
  return request({
    url: "/itf/common/cjrh/expert/getSchExperts.json",
    method: "get",
    params:params,
  });
}
// 根据Id查询职教专家详细信息
export function getSchExpertById(params) {
  return request({
    url: "/itf/common/cjrh/expert/getSchExpertById.json",
    method: "get",
    params:params,
  });
}

// 翻页查询企业行业专家库
export function getEntExperts(params) {
  return request({
    url: "/itf/common/cjrh/expert/getEntExperts.json",
    method: "get",
    params:params,
  });
}
// 根据Id查询企业行业专家详细信息
export function getEntExpertById(params) {
  return request({
    url: "/itf/common/cjrh/expert/getEntExpertById.json",
    method: "get",
    params:params,
  });
}